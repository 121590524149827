<template>
  <div>
    <a-spin :spinning="isCalculating">
      <h2 class="form-title mb-4 mt-0">
        {{ $t('Information') }}
      </h2>
      <div class="columns is-multiline">
        <div class="column is-3">
          <table class="calculation-table is-fullwidth table">
            <tr>
              <td>{{ $t('Area ADR/sqm') }}</td>
              <td>{{ $filters.number(result.areaADRsqm) + ' JPY' }}</td>
            </tr>
            <tr>
              <td>{{ $t('Total occupied area (room total)') }}</td>
              <td>{{ $filters.number(result.totalOccupiedRoomArea) + ' sqm' }}</td>
            </tr>
            <tr>
              <td>{{ $t('Total occupied area (privately occupied space total)') }}</td>
              <td>{{ $filters.number(result.totalOccupiedAreaPrivateTotal) + ' sqm' }}</td>
            </tr>
            <tr>
              <td>{{ $t('Total occupied area') }}</td>
              <td>{{ $filters.number(result.totalOccupiedArea) + ' sqm' }}</td>
            </tr>
            <tr>
              <td>{{ $t('Total capacity') }}</td>
              <td>{{ $filters.number(result.totalCapacity) + ' ppl' }}</td>
            </tr>
            <tr>
              <td>{{ $t('Checkouts') }}</td>
              <td>{{ $filters.number(result.totalCheckouts) + ' c/o' }}</td>
            </tr>
          </table>
        </div>
        <div class="column is-3">
          <table class="calculation-table is-fullwidth table">
            <tr>
              <td>{{ $t('Area sqm') }}</td>
              <td>{{ $filters.number(result.areaSqm) + ' sqm' }}</td>
            </tr>
            <tr>
              <td>{{ $t('Gross annual sales') }}</td>
              <td>{{ $filters.number(Math.trunc(result.totalGrossAnnualSales)) + ' JPY' }}</td>
            </tr>
            <tr>
              <td>{{ $t('Gross annual sales w/o tax') }}</td>
              <td>{{ $filters.number(Math.trunc(result.totalGrossAnnualSalesNoTax)) + ' JPY' }}</td>
            </tr>
            <tr>
              <td>{{ $t('Monthly sales') }}</td>
              <td>{{ $filters.number(Math.trunc(result.totalMonthlySales)) + ' JPY' }}</td>
            </tr>
            <tr>
              <td>{{ $t('Monthly sales w/o tax') }}</td>
              <td>{{ $filters.number(Math.trunc(result.totalMonthlySalesNoTax)) + ' JPY' }}</td>
            </tr>
          </table>
        </div>
        <div class="column is-3">
          <table class="calculation-table is-fullwidth table">
            <tr>
              <td>{{ $t('Estimated room nights (room only)') }}</td>
              <td>
                {{ $filters.number(result.totalEstimatedRoomNightsRoomOnly) }} {{ $t('nights') }}
              </td>
            </tr>
            <tr>
              <td>{{ $t('Estimated room nights (meal & stay)') }}</td>
              <td>
                {{ $filters.number(result.totalEstimatedRoomNightsMealAndStay) }} {{ $t('nights') }}
              </td>
            </tr>
            <tr>
              <td>{{ $t('Estimated room nights (total)') }}</td>
              <td>
                {{ $filters.number(result.totalEstimatedRoomNightsTotal) }} {{ $t('nights') }}
              </td>
            </tr>
            <tr>
              <td>{{ $t('Total rooms available') }}</td>
              <td>{{ $filters.number(result.totalRoomsAvailable) }} {{ $t('rooms') }}</td>
            </tr>
            <tr>
              <td>{{ $t('Total estimated OCC') }}</td>
              <td>{{ $filters.number(result.totalEstimatedOCC * 100) + '%' }}</td>
            </tr>
          </table>
        </div>
        <div class="column is-3">
          <div class="table-container">
            <table class="calculation-table is-fullwidth table">
              <tr>
                <td>{{ $t('Prefecture average OCC') }}</td>
                <td>{{ $filters.number(result.prefectureAverageOCC * 100) + '%' }}</td>
              </tr>
              <tr>
                <td>{{ $t('Prefecture ryokan OCC') }}</td>
                <td>{{ $filters.number(result.prefectureRyokanOCC * 100) + '%' }}</td>
              </tr>
              <tr>
                <td>{{ $t('Prefecture resort OCC') }}</td>
                <td>{{ $filters.number(result.prefectureResortOCC * 100) + '%' }}</td>
              </tr>
              <tr>
                <td>{{ $t('Prefecture business hotel OCC') }}</td>
                <td>{{ $filters.number(result.prefectureBussinessHotelOCC * 100) + '%' }}</td>
              </tr>
              <tr>
                <td>{{ $t('Prefecture city OCC') }}</td>
                <td>{{ $filters.number(result.prefectureCityOCC * 100) + '%' }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div v-if="result.roomTypes && result.roomTypes.length > 0">
        <h2 class="form-title mt-6">
          {{ $t('Room') }}
        </h2>
        <div class="columns is-multiline">
          <ResultRoomType
            v-for="(roomType, index) in result.roomTypes"
            :key="index"
            :value="roomType"
            :index="index"
            class="column is-12"
            @update="(vars) => { handleVarsUpdate(vars, index) }"
          />
        </div>
      </div>
    </a-spin>
  </div>
</template>

<i18n>
{
  "en": {
  },
  "ja": {
  }
}
</i18n>

<script>
import {
  round,
  num,
  convertPercent,
  spdbBaseQuery,
  log,
} from '@/views/simulations/components/utils';
import ResultRoomType from '@/views/simulations/components/calculation/ResultRoomType';

export default {
  name: 'Result',
  components: {
    ResultRoomType,
  },
  props: {
    simulation: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  emits: ['calculated'],
  data() {
    return {
      size: 'large',
      isCalculating: false,
      spdb: {
        areaSqm: 0,
        supposedAreaInventory: 0,
        prefectureSupposedInventories: [],
        totalReservations: 0,
        rtNumberOfNights: 0,
        areaADR: 0,
        areaRoomOnly: 0,
        areaMealFull: 0,
        areaMealBreakfast: 0,
        areaMealDinner: 0,
      },
      occBeforeValues: {
        medianModelOCC: 0,
        medianRTPossession: 0,
      },
      result: {
        areaSqm: 0,
        areaADRsqm: 0,
        totalOccupiedRoomArea: 0,
        totalOccupiedAreaPrivateTotal: 0,
        totalOccupiedArea: 0,
        totalCapacity: 0,
        totalCheckouts: 0,
        totalRoomsAvailable: 0,
        totalNights: 0,
        totalPersonNights: 0,
        totalGrossAnnualSales: 0,
        totalGrossAnnualSalesNoTax: 0,
        totalMonthlySales: 0,
        totalMonthlySalesNoTax: 0,
        totalEstimatedRoomNightsRoomOnly: 0,
        totalEstimatedRoomNightsMealAndStay: 0,
        totalEstimatedRoomNightsTotal: 0,
        totalEstimatedOCC: 0,
        prefectureAverageOCC: 0,
        prefectureRyokanOCC: 0,
        prefectureResortOCC: 0,
        prefectureBussinessHotelOCC: 0,
        prefectureCityOCC: 0,
        roomTypes: [],
      },
    };
  },
  computed: {
    adj() {
      const simulation = this.simulation || {};
      const adj = simulation.calculationAdjustments || {};

      return {
        ...adj,
        indexForSoldRatio: convertPercent(adj.indexForSoldRatio),
        indexForModelOCC: convertPercent(adj.indexForModelOCC),
        benchmarkForExceedingPrivatelyOccupiedSpace: convertPercent(
          adj.benchmarkForExceedingPrivatelyOccupiedSpace,
        ),
        indexForPrivatelyOccupiedSpaceExceeding: convertPercent(
          adj.indexForPrivatelyOccupiedSpaceExceeding,
        ),
        benchmarkForPeakedPrivatelyOccupiedSpace: convertPercent(
          adj.benchmarkForPeakedPrivatelyOccupiedSpace,
        ),
        indexForPeakedPrivatelyOccupiedSpace: convertPercent(
          adj.indexForPeakedPrivatelyOccupiedSpace,
        ),
        tax: convertPercent(adj.tax),
        competitorIndexForRoomOnlyPlan: convertPercent(adj.competitorIndexForRoomOnlyPlan),
        competitorIndexForMealAndStayPlan: convertPercent(adj.competitorIndexForMealAndStayPlan),
        rtMarketShare: convertPercent(adj.rtMarketShare),
        discount: convertPercent(adj.discount),
        winRateForMealAndStayPlan: convertPercent(adj.winRateForMealAndStayPlan),
        winRateForRoomOnlyPlan: convertPercent(adj.winRateForRoomOnlyPlan),
      };
    },
  },
  watch: {
    simulation: {
      immediate: true,
      deep: true,
      handler(nv) {
        if (nv && nv.spdb && nv.occAverages && nv.occBeforeValues) {
          const {
            spdb,
            occAverages,
            occBeforeValues,
            ...simulation
          } = nv;

          this.spdb = spdb || {};
          this.setPrefectureOccAverages(occAverages);
          this.setOccBeforeValues(occBeforeValues);
          this.calculate(simulation);
        }
      },
    },
  },
  methods: {
    setPrefectureOccAverages(occAverages = {}) {
      this.result.prefectureAverageOCC = occAverages.avgOCC || 0;
      this.result.prefectureRyokanOCC = occAverages.ryokanOCC || 0;
      this.result.prefectureResortOCC = occAverages.resortOCC || 0;
      this.result.prefectureBussinessHotelOCC = occAverages.businessHotelOCC || 0;
      this.result.prefectureCityOCC = occAverages.cityOCC || 0;
    },
    setOccBeforeValues(values = {}) {
      this.occBeforeValues.medianModelOCC = values.medianModelOCC || 0;
      this.occBeforeValues.medianRTPossession = values.medianRTPossession || 0;
    },
    setCalculating(state) {
      this.isCalculating = state;
    },
    calculated() {
      this.setCalculating(false);
      this.$emit('calculated', this.result);
    },
    async handleVarsUpdate(vars, index) {
      this.setCalculating(true);
      const { roomTypes } = this.result;

      roomTypes[index] = { ...roomTypes[index], vars };

      this.result.roomTypes = await this.calculateRoomTypes(roomTypes);
      this.calculated();
    },
    async calculate(simulation) {
      this.setCalculating(true);
      const { roomTypes } = simulation;
      const { areaSqm, areaADR } = this.spdb;

      this.result.areaSqm = areaSqm;
      this.result.areaADRsqm = (areaADR / areaSqm) || 0;

      const sorted = roomTypes
        .filter((type) => type.name)
        .sort((a, b) => b.isChecked - a.isChecked);

      this.result.roomTypes = await this.calculateRoomTypes(sorted);
      this.calculated();
    },
    async calculateRoomTypes(roomTypes = []) {
      const rooms = [];
      let totalOccupiedRoomArea = 0;
      let totalOccupiedAreaPrivateTotal = 0;
      let totalOccupiedArea = 0;
      let totalCapacity = 0;
      let totalCheckouts = 0;
      let totalRoomsAvailable = 0;
      let totalNights = 0;
      let totalPersonNights = 0;

      const selectedValues = {
        baseDOR: [],
        addonDOR: [],
        roomBedding: [],
      };

      const occBeforeAdjustment = this.getOccBeforeAdjustment();

      for (let i = 0; i < roomTypes.length; i += 1) {
        const calculated = this.calculateRoomType(
          this.prepareRoomType(roomTypes[i]),
          occBeforeAdjustment,
        );
        const { isChecked, result } = calculated;

        totalOccupiedRoomArea += result.occupiedRoomArea;
        totalOccupiedAreaPrivateTotal += result.occupiedPrivateArea;
        totalOccupiedArea += result.occupiedArea;
        totalCapacity += result.capacity;
        totalCheckouts += result.checkouts;
        totalRoomsAvailable += result.roomsAvailable;
        totalNights += result.nights;
        totalPersonNights += result.personNights;

        if (isChecked) {
          selectedValues.baseDOR.push(result.baseDOR);
          selectedValues.addonDOR.push(result.addonDOR);
          selectedValues.roomBedding.push(result.roomBedding);
        }

        rooms.push(calculated);
      }

      this.setTotalRoomCalculations({
        totalOccupiedRoomArea,
        totalOccupiedAreaPrivateTotal,
        totalOccupiedArea,
        totalCapacity,
        totalCheckouts,
        totalRoomsAvailable,
        totalNights,
        totalPersonNights,
      });

      return this.postCalculateRoomTypes(rooms, occBeforeAdjustment, selectedValues);
    },
    getOccBeforeAdjustment() {
      log('------------Calculating OCC Before Adjustment...------------');
      const { prefectureAverageOCC } = this.result;
      const { indexForSoldRatio, indexForModelOCC } = this.adj;
      const { supposedAreaInventory, rtNumberOfNights } = this.spdb;
      const { medianModelOCC, medianRTPossession } = this.occBeforeValues;

      log('Supposed Area Inventory', supposedAreaInventory, {
        formula: 'Total Number of Rooms * 365',
        values: `${supposedAreaInventory / 365} * 365`,
      });

      const rtPossession = num(rtNumberOfNights / supposedAreaInventory);
      log('RT Possession', rtPossession, {
        formula: 'RT Number of Nights / Supposed Area Inventory',
        values: `${rtNumberOfNights} / ${supposedAreaInventory}`,
      });

      const adjustmentIndexOfAreaPossession = (
        rtPossession - ((rtPossession - medianRTPossession) * indexForSoldRatio)
      );

      log('Adjustment Index of Area Possession', adjustmentIndexOfAreaPossession, {
        formula: 'RT Possession - ((RTPossession - Median RT Possession) * Index For Sold Ratio)',
        values: `${rtPossession} - ((${rtPossession} - ${medianRTPossession}) * ${indexForSoldRatio})`,
      });

      const applicableRegionAdjustedRTNights = (
        supposedAreaInventory * adjustmentIndexOfAreaPossession
      );

      log('Applicable Region Adjusted RT Nights', applicableRegionAdjustedRTNights, {
        formula: 'Supposed Area Inventory  * Adjustment Index of Area Possession',
        values: `${supposedAreaInventory} * ${adjustmentIndexOfAreaPossession}`,
      });

      const {
        totalApplicableRegionAdjustedRTNights,
        totalSupposedAreaInventory,
      } = this.getTotalApplicableRegionAdjustedRTNights(adjustmentIndexOfAreaPossession);

      const applicableRegionNights = totalSupposedAreaInventory * prefectureAverageOCC;

      log('Applicable Region Nights', applicableRegionNights, {
        formula: 'Total Supposed Area Inventory  * Prefecture Average OCC',
        values: `${totalSupposedAreaInventory} * ${prefectureAverageOCC}`,
      });

      const supposedAreaNumberOfNights = num(
        applicableRegionAdjustedRTNights / totalApplicableRegionAdjustedRTNights,
      ) * applicableRegionNights;

      log('Supposed Area Number of Nights', supposedAreaNumberOfNights, {
        formula: '(Applicable Region Adjusted RT Nights  / Total Applicable Region Adjusted RT Nights) * Applicable Region Nights',
        values: `(${applicableRegionAdjustedRTNights} / ${totalApplicableRegionAdjustedRTNights}) * ${applicableRegionNights}`,
      });

      const modelOCC = num(supposedAreaNumberOfNights / supposedAreaInventory);

      log('Model OCC', modelOCC, {
        formula: 'Supposed Area Number of Nights / Supposed Area Inventory',
        values: `${supposedAreaNumberOfNights} / ${supposedAreaInventory}`,
      });

      const result = modelOCC - ((modelOCC - medianModelOCC) * indexForModelOCC);

      log('OCC Before Adjustment', result, {
        formula: 'Model OCC - ((Model OCC - Median Model OCC) * Index for Model OCC)',
        values: `${modelOCC} - ((${modelOCC} - ${medianModelOCC}) * ${indexForModelOCC})`,
      });

      return result;
    },
    getTotalApplicableRegionAdjustedRTNights(adjustmentIndexOfAreaPossession) {
      const { prefectureSupposedInventories } = this.spdb;
      let totalApplicableRegionAdjustedRTNights = 0;
      let totalSupposedAreaInventory = 0;

      for (let i = 0; i < prefectureSupposedInventories.length; i += 1) {
        const { inventory } = prefectureSupposedInventories[i];
        const adjusted = inventory * adjustmentIndexOfAreaPossession;
        const totalRooms = inventory / 365;

        log(`Total Supposed Area Inventory: ${i + 1}`, inventory, {
          formula: 'Total Rooms * 365',
          values: `${totalRooms} * 365 `,
        });

        log(`Total Applicable Region Adjusted RT Nights: ${i + 1}`, adjusted, {
          formula: 'Total Rooms * 365 * Adjustment Index of Area Posession',
          values: `${totalRooms} * 365 *  ${adjustmentIndexOfAreaPossession}`,
        });

        totalSupposedAreaInventory += inventory;
        totalApplicableRegionAdjustedRTNights += adjusted;
      }

      return { totalSupposedAreaInventory, totalApplicableRegionAdjustedRTNights };
    },
    prepareRoomType(type) {
      // default undefined values to 0
      const data = {};
      Object.keys(type).forEach((key) => {
        data[key] = type[key] ?? 0;
      });

      return data;
    },
    calculateRoomType(type, occBeforeAdjustment) {
      const { indexForBaseDOR, indexForAddonDOR } = this.adj;
      const {
        roomCount,
        occupiedRoomArea,
        occupiedPrivateArea,
        capacity,
        dorAdjustments,
        name,
      } = type;

      const result = {
        occupiedRoomArea: occupiedRoomArea * roomCount,
        occupiedPrivateArea: occupiedPrivateArea * roomCount,
        capacity: capacity * roomCount,
        roomsAvailable: roomCount * 365,
      };

      log(`\n***** Room Type Calculations: ${name} *****`);

      log('Occupied Room Area', result.occupiedRoomArea, {
        formula: 'Occupied Room Area * Room Count',
        values: `${occupiedRoomArea} * ${roomCount}`,
      });

      log('Occupied Private Area', result.occupiedPrivateArea, {
        formula: 'Occupied Private Area * Room Count',
        values: `${occupiedPrivateArea} * ${roomCount}`,
      });

      log('Capacity', result.capacity, {
        formula: 'Capacity * Room Count',
        values: `${occupiedPrivateArea} * ${roomCount}`,
      });

      log('Rooms Available', result.roomsAvailable, {
        formula: 'Room Count * 365',
        values: `${roomCount} * 365`,
      });

      result.occupiedArea = (result.occupiedRoomArea + result.occupiedPrivateArea);
      result.occupiedSpacePerRoom = (occupiedRoomArea + occupiedPrivateArea);

      log('Occupied Area', result.occupiedArea, {
        formula: 'Occupied Room Area + Occupied Private Room Area',
        values: `${result.occupiedRoomArea} + ${result.occupiedPrivateArea}`,
      });

      log('Occupied Space Per Room', result.occupiedArea, {
        formula: 'Occupied Area + Occupied Private Area',
        values: `${occupiedRoomArea} + ${occupiedPrivateArea}`,
      });

      // overrides takes precedence
      const vars = type.vars || {};
      vars.occ = vars.occ || this.getRoomTypeOcc(type, occBeforeAdjustment);
      vars.los = vars.los || this.getRoomTypeLos(type);

      // nights/checkout
      result.nights = roomCount * 365 * vars.occ;
      result.checkouts = num(result.nights / vars.los);

      log('Nights', result.nights, {
        formula: 'Room Count * 365 * OCC',
        values: `${roomCount} * 365 * ${vars.occ}`,
      });

      log('Checkouts', result.occupiedArea, {
        formula: 'Nights / LOS',
        values: `${result.nights} / ${vars.los}`,
      });

      // post calculation values
      const roomBedding = this.getRoomBeddingCount(type);
      const baseDOR = roomBedding * indexForBaseDOR;
      const addonDOR = (capacity - roomBedding) * indexForAddonDOR;
      const roomDOR = baseDOR + addonDOR + dorAdjustments;

      log('Base DOR', baseDOR, {
        formula: 'Room Bedding / Index for Base DOR',
        values: `${roomBedding} / ${indexForBaseDOR}`,
      });

      log('Addon DOR', addonDOR, {
        formula: '(Capacity - Room Bedding) * Index for Addon DOR',
        values: `(${capacity} - ${roomBedding}) * ${indexForAddonDOR}`,
      });

      log('Room DOR', addonDOR, {
        formula: 'Base DOR + Addon DOR + DOR Adjustments',
        values: `${baseDOR} + ${addonDOR} + ${dorAdjustments}`,
      });

      result.roomBedding = roomBedding;
      result.baseDOR = baseDOR;
      result.addonDOR = addonDOR;
      result.roomDOR = roomDOR;

      // person nights
      result.personNights = result.nights * roomDOR;

      log('Person Nights', result.personNights, {
        formula: 'Nights * Room DOR',
        values: `${result.nights} * ${roomDOR}`,
      });

      return { ...type, vars, result };
    },
    async postCalculateRoomTypes(preRoomTypes, occBeforeAdjustment, selectedValues) {
      const rooms = [];
      let totalGrossAnnualSales = 0;
      let totalGrossAnnualSalesNoTax = 0;
      let totalMonthlySales = 0;
      let totalMonthlySalesNoTax = 0;
      let totalEstimatedRoomNightsRoomOnly = 0;
      let totalEstimatedRoomNightsMealAndStay = 0;
      let totalEstimatedRoomNightsTotal = 0;
      let totalEstimatedOCC = 0;

      const minDOR = num(
        Math.min(...selectedValues.baseDOR) + Math.min(...selectedValues.addonDOR),
      );
      const minBedding = num(Math.min(...selectedValues.roomBedding));

      for (let i = 0; i < preRoomTypes.length; i += 1) {
        const preRoomType = preRoomTypes[i];
        log(`\n***** Room Type Post Calculations: ${preRoomType.name} *****`);
        const adrAfterAdjustment = this.getAdrAfterAdjustment(preRoomType, minDOR, minBedding);
        // eslint-disable-next-line no-await-in-loop
        const calculated = await this.calculatePostRoomType(
          preRoomType,
          occBeforeAdjustment,
          adrAfterAdjustment,
        );
        const { result } = calculated;

        totalGrossAnnualSales += result.grossAnnualSales;
        totalGrossAnnualSalesNoTax += result.grossAnnualSalesNoTax;
        totalMonthlySales += result.monthlySales;
        totalMonthlySalesNoTax += result.monthlySalesNoTax;

        totalEstimatedRoomNightsRoomOnly += result.estimatedRoomNightsRoomOnly;
        totalEstimatedRoomNightsMealAndStay += result.estimatedRoomNightsMealAndStay;
        totalEstimatedRoomNightsTotal += result.estimatedRoomNightsTotal;
        totalEstimatedOCC += result.estimatedOCC;

        rooms.push(calculated);
      }

      this.setTotalRoomPostCalculations({
        totalGrossAnnualSales,
        totalGrossAnnualSalesNoTax,
        totalMonthlySales,
        totalMonthlySalesNoTax,
        totalEstimatedRoomNightsRoomOnly,
        totalEstimatedRoomNightsMealAndStay,
        totalEstimatedRoomNightsTotal,
        totalEstimatedOCC,
      });

      return rooms;
    },
    async calculatePostRoomType(preRoomType, occBeforeAdjustment, adrAfterAdjustment) {
      const { tax } = this.adj;
      const { result, vars, ...roomType } = preRoomType;
      const { roomAddonIndexes } = roomType;
      const { roomDOR, nights, occupiedSpacePerRoom } = result;

      const sales = this.calculateRoomTypeSales(preRoomType, adrAfterAdjustment);
      const sumAddonDOR = roomAddonIndexes.reduce((sum, addon) => sum + addon.dor, 0);
      // adr and dor overrides will take precedence
      vars.adr = vars.adr || num(sales.grossAnnualSales / this.result.totalNights);
      vars.dor = vars.dor || num(this.result.totalPersonNights / nights) + sumAddonDOR;

      log('ADR', vars.adr, {
        formula: 'Gross Annual Sales / Total Nights',
        values: `${sales.grossAnnualSales} / ${this.result.totalNights}`,
      });

      log('DOR', vars.dor, {
        formula: "Total Person Nights / Nights + Sum of selected feature's Room Add-on DOR",
        values: `(${this.result.totalPersonNights} / ${nights}) + ${sumAddonDOR}`,
      });

      const estimated = await this.calculateRoomTypeEstimatedRoomNights(
        adrAfterAdjustment,
        roomDOR,
      );

      // RevPAR calculations
      result.revPAR = vars.adr * vars.occ;
      result.adrSqm = num(adrAfterAdjustment / occupiedSpacePerRoom);
      result.revPARsqm = result.adrSqm * occBeforeAdjustment;
      result.revPARsqmNoTax = num(result.revPARsqm / (1 + tax));

      log('RevPAR', result.revPAR, {
        formula: 'ADR * OCC',
        values: `${vars.adr} * ${vars.occ}`,
      });

      log('ADR SQM', result.adrSqm, {
        formula: 'ADR After Adjustment / Occupied Space per Room',
        values: `${adrAfterAdjustment} / ${occupiedSpacePerRoom}`,
      });

      log('RevPAR SQM', result.revPARsqm, {
        formula: 'ADR SQM * OCC Before Adjustment',
        values: `${result.adrSqm} * ${occBeforeAdjustment}`,
      });

      log('RevPAR SQM w/o Tax', result.revPARsqm, {
        formula: 'RevPAR SQM / (1 + tax)',
        values: `${result.revPARsqm} / (1 + ${tax})`,
      });

      // ARPP calculations
      result.arpp = num(adrAfterAdjustment / vars.dor);
      result.arppNoTax = num(result.arpp / (1 + tax));
      result.twoPaxARPP = num(adrAfterAdjustment / 2);
      result.twoPaxARPPNoTax = num(result.twoPaxARPP / (1 + tax));
      result.minARPP = num(adrAfterAdjustment / result.capacity);

      log('ARPP', result.arpp, {
        formula: 'ADR After Adjustment / DOR',
        values: `${adrAfterAdjustment} / ${vars.dor}`,
      });

      log('ARPP w/o Tax', result.arppNoTax, {
        formula: 'ARPP / (1 + Tax)',
        values: `${result.arpp} / (1 + ${tax})`,
      });

      log('2PAX ARPP', result.twoPaxARPP, {
        formula: 'ADR After Adjustment / 2',
        values: `${adrAfterAdjustment} / 2`,
      });

      log('2PAX ARPP w/o Tax', result.twoPaxARPPNoTax, {
        formula: '2PAX ARPP / (1 + Tax)',
        values: `${result.twoPaxARPPNoTax} / (1 + ${tax})`,
      });

      log('Min ARPP', result.arpp, {
        formula: 'ADR After Adjustment / Capacity',
        values: `${adrAfterAdjustment} / ${result.capcity}`,
      });

      return {
        ...roomType,
        result: { ...result, ...sales, ...estimated },
        vars,
      };
    },
    calculateRoomTypeSales(preRoomType, adrAfterAdjustment) {
      const { tax } = this.adj;
      const { vars, roomCount } = preRoomType;
      const { occ } = vars;

      const sales = {};
      const revparEachRoom = adrAfterAdjustment * occ;

      log('RevPAR per each Room', revparEachRoom, {
        formula: 'ADR After Adjustment * OCC',
        values: `${adrAfterAdjustment} * ${occ}`,
      });

      sales.grossAnnualSales = roomCount * 365 * revparEachRoom;
      sales.grossAnnualSalesNoTax = num(sales.grossAnnualSales / (1 + tax));
      sales.monthlySales = num(sales.grossAnnualSales / 12);
      sales.monthlySalesNoTax = num(sales.monthlySales / (1 + tax));

      log('Gross Annual Sales', sales.grossAnnualSales, {
        formula: 'Room Count * 365 * RevPAR per each Room',
        values: `${roomCount} * 365 * ${revparEachRoom}`,
      });

      log('Gross Annual Sales w/o Tax', sales.grossAnnualSalesNoTax, {
        formula: 'Gross Annual Sales / (1 + Tax)',
        values: `${sales.grossAnnualSales} / (1 + ${tax})`,
      });

      log('Monthly Sales', sales.monthlySales, {
        formula: 'Gross Annual Sales / 12',
        values: `${sales.grossAnnualSales} / 12`,
      });

      log('Monthly Sales w/o Tax', sales.monthlySalesNoTax, {
        formula: 'Monthly Sales / (1 + Tax)',
        values: `${sales.monthlySales} / (1 + ${tax})`,
      });

      return sales;
    },
    async calculateRoomTypeEstimatedRoomNights(adrAfterAdjustment, roomDOR) {
      const estimated = {};
      const {
        rtMarketShare,
        competitorIndexForRoomOnlyPlan,
        competitorIndexForMealAndStayPlan,
        winRateForRoomOnlyPlan,
        winRateForMealAndStayPlan,
      } = this.adj;
      const { areaRoomOnly } = this.spdb;
      const [resultRoomOnly, resultMealAndStay] = await Promise.all([
        this.getResultRoomOnly(adrAfterAdjustment),
        this.getResultMealAndStay(adrAfterAdjustment, roomDOR),
      ]);

      const areaMealPlan = 1 - areaRoomOnly;
      const marketFactor = (areaMealPlan * competitorIndexForRoomOnlyPlan) + (
        areaRoomOnly * competitorIndexForMealAndStayPlan
      );

      log('Area Meal Plan', areaMealPlan, {
        formula: '1 - Area Room Only',
        values: `1 - ${areaRoomOnly}`,
      });

      log('Market Factor', marketFactor, {
        formula: `
          (Area Meal Plan * Competitor Index for Room Only Plan) +
          (Area Room Only * Competitor Index for Meal & Stay Plan)
        `,
        values: `
          (${areaMealPlan} * ${competitorIndexForRoomOnlyPlan}) +
          (${areaRoomOnly} * ${competitorIndexForMealAndStayPlan})
        `,
      });

      estimated.estimatedRoomNightsRoomOnly = Math.ceil(
        resultRoomOnly * winRateForRoomOnlyPlan * num(marketFactor / rtMarketShare),
      );
      estimated.estimatedRoomNightsMealAndStay = Math.ceil(
        resultMealAndStay * winRateForMealAndStayPlan * num(marketFactor / rtMarketShare),
      );
      estimated.estimatedRoomNightsTotal = (
        estimated.estimatedRoomNightsRoomOnly + estimated.estimatedRoomNightsMealAndStay
      );
      estimated.estimatedOCC = num(
        estimated.estimatedRoomNightsTotal / this.result.totalRoomsAvailable,
      );

      log('Estimated Room Nights (Room Only)', estimated.estimatedRoomNightsRoomOnly, {
        formula: 'Result_Room Only * Win Rate for Room Only Plan * (Market Factor / RT Market Share)',
        values: `${resultRoomOnly} * ${winRateForRoomOnlyPlan} * (${marketFactor} / ${rtMarketShare})`,
      });

      log('Estimated Room Nights (Meal & Stay)', estimated.estimatedRoomNightsMealAndStay, {
        formula: 'Result_Meal & Stay * Win Rate for Meal & Stay Plan * (Market Factor / RT Market Share)',
        values: `${resultMealAndStay} * ${winRateForMealAndStayPlan} * (${marketFactor} / ${rtMarketShare})`,
      });

      log('Estimated Room Nights (Total)', estimated.estimatedRoomNightsTotal, {
        formula: 'Estimated Room Nights (Meal & Stay) + Estimated Room Nights (Room Only)',
        values: `${estimated.estimatedRoomNightsMealAndStay} + ${estimated.estimatedRoomNightsRoomOnly}`,
      });

      log('Estimated OCC', estimated.estimatedOCC, {
        formula: 'Estimated Room Nights (Total) / Total Rooms Available',
        values: `${estimated.estimatedRoomNightsTotal} / ${this.result.totalRoomsAvailable}`,
      });

      return estimated;
    },
    async getResultRoomOnly(adrAfterAdjustment) {
      const { histogramClass } = this.adj;
      const baseLimit = num(adrAfterAdjustment / histogramClass);

      log('Room Only Lower/Upper Limit BASE', baseLimit, {
        formula: 'ADR After Adjustment / Histogram Class',
        values: `${adrAfterAdjustment} / ${histogramClass}`,
      });

      return this.getResultNights(baseLimit, 'Room Only');
    },
    async getResultMealAndStay(adrAfterAdjustment, roomDOR) {
      const { histogramClass } = this.adj;
      const areaMealSum = this.getAreaMealSum();
      const baseLimit = num((adrAfterAdjustment + roomDOR * areaMealSum) / histogramClass);

      log('Meal and Stay Lower/Upper Limit BASE', baseLimit, {
        formula: '(ADR After Adjustment + Room DOR + AREA MEALS SUM) / Histogram Class',
        values: `(${adrAfterAdjustment} + ${roomDOR} + ${areaMealSum}) / ${histogramClass}`,
      });

      return this.getResultNights(baseLimit, 'Meal and Stay');
    },
    async getResultNights(baseLimit, logLabel = '') {
      const { histogramClass, pastReservationsInYear } = this.adj;
      const upperLimit = round((baseLimit + 1) * histogramClass);
      const lowerLimit = round((baseLimit - 1) * histogramClass);

      const { data } = await this.$store.dispatch('simulation-spdb/result', {
        ...spdbBaseQuery(this.simulation, pastReservationsInYear),
        lowerLimit,
        upperLimit,
      });

      log(`${logLabel} Upper Limit`, upperLimit, {
        formula: 'round((BASE + 1) * Histogram Class)',
        values: `round((${baseLimit} + 1) * ${histogramClass})`,
      });

      log(`${logLabel} Lower Limit`, lowerLimit, {
        formula: 'round((BASE - 1) * Histogram Class)',
        values: `round((${baseLimit} - 1) * ${histogramClass})`,
      });

      return data;
    },
    getAreaMealSum() {
      const { breakfastPrice, dinnerPrice } = this.adj;
      const { areaMealFull, areaMealBreakfast, areaMealDinner } = this.spdb;

      const breakfast = breakfastPrice * areaMealBreakfast;
      const dinner = dinnerPrice * areaMealDinner;
      const full = (breakfastPrice + dinnerPrice) * areaMealFull;

      log('Area Meal & Stay Breakfast', breakfast, {
        formula: 'Breakfast Price * Area Meal & Stay Breakfast',
        values: `${breakfastPrice} * ${areaMealBreakfast}`,
      });

      log('Area Meal & Stay Dinner', dinner, {
        formula: 'Dinner Price * Area Meal & Stay Dinner',
        values: `${dinnerPrice} * ${areaMealDinner}`,
      });

      log('Area Meal & Stay Full Board', full, {
        formula: 'Full Board Price * Area Meal & Stay Full Board',
        values: `${(breakfastPrice + dinnerPrice)} * ${areaMealFull}`,
      });

      return breakfast + dinner + full;
    },
    getAdrAfterAdjustment(preRoomType, minDOR, minBedding) {
      const {
        discount,
        peakOfRoomSqm,
        indexForBeddingLift,
        indexForRoomBaseADR,
        indexForExcessiveSqm,
        indexForRoomPeakedSqm,
        indexForAverageGuestPerRoom,
        indexForPeakedPrivatelyOccupiedSpace,
        indexForPrivatelyOccupiedSpaceExceeding,
        benchmarkForExceedingPrivatelyOccupiedSpace,
        benchmarkForPeakedPrivatelyOccupiedSpace,
      } = this.adj;
      const { areaADR, areaSqm } = this.spdb;
      const {
        occupiedRoomArea,
        occupiedPrivateArea,
        otherAdjustments,
        fbAdjustments,
        result,
      } = preRoomType;
      const { roomDOR, roomBedding } = result;

      const adoptedArea = num(areaADR / areaSqm);
      const generalAddonSum = this.getGeneralAddonsSum();
      const adoptedUnitPrice = num((adoptedArea + generalAddonSum) / (1 + discount));
      const roomBase = adoptedUnitPrice * areaSqm * indexForRoomBaseADR;

      log('Adopted Unit Price', adoptedUnitPrice, {
        formula: '((areaADR / areaSqm) + generalAddonSum) / (1 + discount)',
        values: `((${areaADR} / ${areaSqm}) + ${generalAddonSum}) / (1 + ${discount})`,
      });

      log('Room Base', roomBase, {
        formula: 'Adopted Unit Price * Area SQM * Index for Room Base DOR',
        values: `${adoptedUnitPrice} * ${areaSqm} * ${indexForRoomBaseADR}`,
      });

      const roomSqm1 = occupiedRoomArea - areaSqm;
      const roomSqm2 = occupiedRoomArea > peakOfRoomSqm ? (occupiedRoomArea - peakOfRoomSqm) : 0;
      const sqmLift1 = adoptedUnitPrice * roomSqm1 * indexForExcessiveSqm;
      const sqmLift2 = adoptedUnitPrice * roomSqm2 * indexForRoomPeakedSqm;

      log('Room SQM 1', roomSqm1, {
        formula: 'Occupied Room Area - Area SQM',
        values: `${occupiedRoomArea} - ${areaSqm}`,
      });

      log('Room SQM 2', roomSqm2, {
        formula: 'If Occupied Room Area > Peak of Room SQM: Occupied Room Area - Peak of Room SQM',
        values: `(${occupiedRoomArea} - ${peakOfRoomSqm}) or 0`,
      });

      log('SQM Lift 1', sqmLift1, {
        formula: 'Adopted Unit Price * Room SQM 1 * Index for Excessive SQM',
        values: `${adoptedUnitPrice} * ${roomSqm1} * ${indexForExcessiveSqm}`,
      });

      log('SQM Lift 2', sqmLift2, {
        formula: 'Adopted Unit Price * Room SQM 2 * Index for Room Peaked SQM',
        values: `${adoptedUnitPrice} * ${roomSqm2} * ${indexForRoomPeakedSqm}`,
      });

      const liftMinDOR = num(areaADR / minDOR);
      const avgNumGuestsLift = (roomDOR - minDOR) * liftMinDOR * indexForAverageGuestPerRoom;

      log('Average Number of Guests Lift', avgNumGuestsLift, {
        formula: '(Room DOR - Min DOR) * (ADR / Min DOR) * Index for Average Guest Per Room (AGPR)',
        values: `(${roomDOR} - ${minDOR}) * (${areaADR} / ${minDOR}) * ${indexForAverageGuestPerRoom}`,
      });

      const beddingLift = (roomBedding - minBedding) * liftMinDOR * indexForBeddingLift;

      log('Bedding Lift', beddingLift, {
        formula: '(Room Bedding - Min Bedding) * (ADR / Min DOR) * Index for Bedding Lift',
        values: `(${roomBedding} - ${minBedding}) * (${areaADR} / ${minDOR}) * ${indexForBeddingLift}`,
      });

      const baseLift = num(occupiedPrivateArea / occupiedRoomArea);
      const nonGuestRoomLift1Arg = baseLift > benchmarkForExceedingPrivatelyOccupiedSpace
        ? occupiedPrivateArea * (1 - benchmarkForExceedingPrivatelyOccupiedSpace)
        : 1;
      const nonGuestRoomLift1 = (
        nonGuestRoomLift1Arg * indexForPrivatelyOccupiedSpaceExceeding * adoptedUnitPrice
      );

      log('Non Guest Room Lift 1', nonGuestRoomLift1, {
        formula: `
          (Occupied Private Area * (1 - Benchmark for Exceeding Privately Occupied Space) or 1) *
          Index for Privately Occupied Space Exeeding * Adopted Unit Price
        `,
        values: `${nonGuestRoomLift1Arg} * ${indexForPrivatelyOccupiedSpaceExceeding} * ${adoptedUnitPrice}`,
      });

      let nonGuestRoomLift2Arg = 0;

      if (baseLift > benchmarkForPeakedPrivatelyOccupiedSpace) {
        nonGuestRoomLift2Arg = (
          occupiedPrivateArea * (occupiedPrivateArea / occupiedRoomArea)
        ) - benchmarkForPeakedPrivatelyOccupiedSpace;
      }

      const nonGuestRoomLift2 = (
        nonGuestRoomLift2Arg * indexForPeakedPrivatelyOccupiedSpace * adoptedUnitPrice
      );

      log('Non Guest Room Lift 2', nonGuestRoomLift2Arg, {
        formula: `
          (Occupied Private Area * ((occupiedPrivateArea * (occupiedPrivateArea / occupiedRoomArea)) - Benchmark for Peaked Privately Occupied Space) or 0) *
          Index for Peaked Privately Occupied Space * Adopted Unit Price
        `,
        values: `${nonGuestRoomLift2} * ${indexForPeakedPrivatelyOccupiedSpace} * ${adoptedUnitPrice}`,
      });

      const roomAddonSum = this.getRoomAddonsSum(preRoomType);
      const adrAfterAdjustment = roomBase + sqmLift1 + sqmLift2 + avgNumGuestsLift
        + beddingLift + nonGuestRoomLift1 + nonGuestRoomLift2
        + roomAddonSum + fbAdjustments + otherAdjustments;

      log('ADR after Adjustment', nonGuestRoomLift2Arg, {
        formula: `
        Room Base + SQM Lift 1 + SQM Lift 2 + Average Number of Guests Lift + Bedding Lift +
        Non Guest Room Lift 1 +  Non Guest Room Lift 2 + Room Add-ons + F&B Adjustments + Other Adjustments
        `,
        values: `
          ${roomBase} + ${sqmLift1} + ${sqmLift2} + ${avgNumGuestsLift} + ${beddingLift} +
          ${nonGuestRoomLift1} + ${nonGuestRoomLift2} + ${roomAddonSum} + ${fbAdjustments} + ${otherAdjustments}
        `,
      });

      return adrAfterAdjustment;
    },
    getRoomTypeLos(roomType) {
      const { rtNumberOfNights, totalReservations } = this.spdb;
      const roomAddonIndexes = roomType.roomAddonIndexes || [];
      const sumRoomAddonLos = roomAddonIndexes.reduce((sum, addon) => sum + addon.los, 0);

      const los = num(rtNumberOfNights / totalReservations) + sumRoomAddonLos;

      log('Room Type LOS', los, {
        formula: '(RT Number of Nights / Total Reservations) + Sum of Room Addon LOS',
        values: `(${rtNumberOfNights} / ${totalReservations}) + ${sumRoomAddonLos}`,
      });

      return los;
    },
    getRoomTypeOcc(roomType, occBeforeAdjustment) {
      const { occAdjustments, roomAddonIndexes } = roomType;
      const sumRoomAddonOcc = roomAddonIndexes
        .reduce((sum, addon) => sum + convertPercent(addon.occ), 0);

      const occ = occBeforeAdjustment + convertPercent(occAdjustments) + sumRoomAddonOcc;

      log('Room Type OCC', occ, {
        formula: 'OCC Before Adjustment + Room Type OCC Adjustments + Sum of Room Addon OCC',
        values: `${occBeforeAdjustment} + ${occAdjustments} + ${sumRoomAddonOcc}`,
      });

      return occ;
    },
    getRoomBeddingCount(roomType) {
      const {
        beddingCountOfDoubleBed,
        beddingCountOfSingleBed,
        beddingCountOfDoubleFuton,
        beddingCountOfSingleFuton,
      } = this.adj;
      const {
        doubleCount,
        doubleFutonCount,
        singleCount,
        singleFutonCount,
      } = roomType;

      const result = (
        (beddingCountOfDoubleBed * doubleCount)
        + (beddingCountOfDoubleFuton * doubleFutonCount)
        + (beddingCountOfSingleBed * singleCount)
        + (beddingCountOfSingleFuton * singleFutonCount)
      );

      log('Room Bedding Count', result, {
        formula: `
          (Bedding Count of Double Bed * No. of Double) +
          (Bedding Count of Double Futon * No. of Double Futon) +
          (Bedding Count of Single Bed * No. of Single) +
          (Bedding Count of Single Futon * No. of Single Futon)
        `,
        values: `
          (${beddingCountOfDoubleBed} * ${doubleCount}) +
          (${beddingCountOfDoubleFuton} * ${doubleFutonCount}) +
          (${beddingCountOfSingleBed} * ${singleCount}) +
          (${beddingCountOfSingleFuton} * ${singleFutonCount}) +
        `,
      });

      return result;
    },
    getRoomAddonsSum(roomType) {
      const roomAddonIndexes = roomType.roomAddonIndexes || [];

      return roomAddonIndexes.reduce((sum, addon) => sum + addon.calculatedAmount, 0);
    },
    getGeneralAddonsSum() {
      const generalAddonIndexes = this.simulation.generalAddonIndexes || [];

      return generalAddonIndexes.reduce((sum, addon) => sum + addon.calculatedAmount, 0);
    },
    setTotalRoomCalculations(data) {
      this.result.totalOccupiedRoomArea = data.totalOccupiedRoomArea;
      this.result.totalOccupiedAreaPrivateTotal = data.totalOccupiedAreaPrivateTotal;
      this.result.totalOccupiedArea = data.totalOccupiedArea;
      this.result.totalCapacity = data.totalCapacity;
      this.result.totalCheckouts = data.totalCheckouts;
      this.result.totalRoomsAvailable = data.totalRoomsAvailable;
      this.result.totalNights = data.totalNights;
      this.result.totalPersonNights = data.totalPersonNights;
    },
    setTotalRoomPostCalculations(data) {
      this.result.totalGrossAnnualSales = data.totalGrossAnnualSales;
      this.result.totalGrossAnnualSalesNoTax = data.totalGrossAnnualSalesNoTax;
      this.result.totalMonthlySales = data.totalMonthlySales;
      this.result.totalMonthlySalesNoTax = data.totalMonthlySalesNoTax;
      this.result.totalEstimatedRoomNightsRoomOnly = data.totalEstimatedRoomNightsRoomOnly;
      this.result.totalEstimatedRoomNightsMealAndStay = data.totalEstimatedRoomNightsMealAndStay;
      this.result.totalEstimatedRoomNightsTotal = data.totalEstimatedRoomNightsTotal;
      this.result.totalEstimatedOCC = data.totalEstimatedOCC;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .calculation-table {
  tr > td {
    font-size: 14px;
    padding: 8px 10px;
  }

  tr > td:last-child {
    font-weight: bold;
    text-align: right;
    white-space: nowrap;
  }
}
</style>
