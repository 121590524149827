<template>
  <section class="section">
    <div class="container">
      <Result
        :simulation="request"
        @calculated="handleCalculated"
      />
    </div>
    <div class="calculation-footer-container container">
      <div class="caclulation-footer columns is-desktop">
        <div class="column is-4">
          <label for="remarks">{{ $t('Remarks') }}</label>
          <a-input
            id="remarks"
            v-model:value="form.remarks"
            type="text"
            size="large"
          />
        </div>
        <SaveForm
          class="column has-text-right is-8"
          :simulation-id="request.id"
          :version="request.version"
          :request-id="request.requestId"
          @create="handleCreate"
          @overwrite="handleOverwrite"
          @generate-pdf="generatePdf"
        />
      </div>
    </div>
  </section>
</template>

<i18n>
{
  "en": {
    "no-existing": "There is no existing verstion to overwrite.",
    "save-success": "Simulation record has been saved."
  },
  "ja": {
    "no-existing": "There is no existing verstion to overwrite.",
    "save-success": "Simulation record has been saved."
  }
}
</i18n>

<script>
import number from '@/filters/number';
import { getLocale } from '@/utils/locale';
import { getResponseError } from '@/utils/util';
import { methods, pdf } from '@/views/simulations/mixins';
import SaveForm from '@/views/simulations/components/calculation/SaveForm';
import Result from '@/views/simulations/components/calculation/Result';

export default {
  name: 'Calculation',
  components: {
    SaveForm,
    Result,
  },
  mixins: [methods, pdf],
  props: {
    request: {
      type: Object,
      default() { return null; },
    },
    removedRoomTypes: {
      type: Array,
      default() { return []; },
    },
  },
  emits: ['simulation-update'],
  data() {
    return {
      form: {},
      result: {},
    };
  },
  watch: {
    request: {
      immediate: true,
      deep: true,
      handler(nv) {
        if (nv) {
          this.form = JSON.parse(JSON.stringify(nv));
        }
      },
    },
  },
  methods: {
    handleCalculated(result) {
      this.result = result;
      this.form.roomTypes = this.result.roomTypes;
    },
    async handleCreate({ requestId, version }) {
      try {
        this.$store.commit('SHOW_FULLSCREEN_LOADER');

        const { roomTypes, ...attr } = this.form;
        const payload = { ...attr, requestId, version };
        payload.roomTypes = roomTypes.map((type) => {
          const copy = { ...type };
          delete copy.id;

          return copy;
        });

        delete payload.id;

        const { data } = await this.$store.dispatch('simulations/add', payload);

        this.$message.success(this.$t('save-success'));
        this.redirect(data.id);
      } catch (e) {
        this.$message.error(getResponseError(e));
      } finally {
        this.$store.commit('HIDE_FULLSCREEN_LOADER');
      }
    },
    async handleOverwrite() {
      try {
        this.$store.commit('SHOW_FULLSCREEN_LOADER');

        const { requestId, version, id } = this.form;
        const params = {
          requestId: encodeURIComponent(requestId),
          version,
        };
        const { data: existing } = await this.$store.dispatch('simulations/getVersion', params);

        if (existing && existing.id !== id) {
          this.$message.error(this.$t('simulation-exists'));

          return;
        }

        await this.$store.dispatch('simulations/update', { ...this.form, id: existing.id });
        await this.removeRoomTypes();

        const updated = await this.getSimulation();

        this.$message.success(this.$t('save-success'));
        this.$emit('simulation-update', updated);
      } catch (e) {
        this.$message.error(getResponseError(e));
      } finally {
        this.$store.commit('HIDE_FULLSCREEN_LOADER');
      }
    },
    async removeRoomTypes() {
      if (this.removedRoomTypes.length > 0) {
        const params = {
          simulationId: this.getSimulationId(),
          ids: this.removedRoomTypes,
        };

        await this.$store.dispatch('simulations/deleteRoomTypes', params);
      }
    },
    redirect(id) {
      this.$router.push({ name: 'simulations-show', params: { id } });
    },
    generatePdf() {
      // force english for now
      const locale = 'en' || getLocale();
      const { coordinates, ...form } = this.form;
      const coordinatesArr = coordinates ? coordinates.split(',') : [];
      const payload = {
        simulation: {
          locale,
          lat: coordinatesArr[0],
          lng: coordinatesArr[1],
          apps: 'google,rakuten',
          address: form.address,
          language: form.language,
          country: form.country,
          placeId: form.placeId,
          sort: form.sort,
          size: form.size,
          requestId: form.requestId,
          version: form.version,
          postalCode: form.postalCode,
          prefecture: form.prefecture,
          areaSmall: form.areaSmall,
          areaDetail: form.areaDetail,
          hasBreakfast: form.hasBreakfast,
          hasDinner: form.hasDinner,
          remarks: form.remarks,
        },
        calculation: this.getResult(),
      };

      this.downloadPdf(payload);
    },
    getResult() {
      const { roomTypes, ...base } = this.result;
      const result = { ...this.getFixedResult(base) };

      const resultRoomTypes = [];

      for (let i = 0; i < roomTypes.length; i += 1) {
        const roomType = { ...roomTypes[i] };

        delete roomType.roomAddonIndexes;
        roomType.vars = this.getFixedResult(roomType.vars);
        roomType.result = this.getFixedResult(roomType.result);

        resultRoomTypes.push(roomType);
      }

      result.roomTypes = resultRoomTypes;

      return result;
    },
    getFixedResult(obj) {
      const result = {};
      const percentageKeys = [
        'occ',
        'estimatedOCC',
        'totalEstimatedOCC',
        'prefectureAverageOCC',
        'prefectureRyokanOCC',
        'prefectureResortOCC',
        'prefectureBussinessHotelOCC',
        'prefectureCityOCC',
      ];
      const truncKeys = [
        'totalGrossAnnualSales',
        'totalGrossAnnualSalesNoTax',
        'totalMonthlySales',
        'totalMonthlySalesNoTax',
        'grossAnnualSales',
        'grossAnnualSalesNoTax',
        'monthlySales',
        'monthlySalesNoTax',
        'revPAR',
        'arpp',
        'arppNoTax',
        'twoPaxARPP',
        'twoPaxARPPNoTax',
        'minARPP',
      ];

      Object.keys(obj).forEach((key) => {
        const num = percentageKeys.includes(key) ? obj[key] * 100 : obj[key];
        const val = truncKeys.includes(key) ? Math.trunc(num) : num;
        const fixed = Number.isFinite(val) ? number(val) : val;

        result[key] = !Number.isNaN(fixed) ? fixed : 0;
      });

      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .calculation-footer-container {
  margin-top: 1rem;
  border-top: 1px solid #DCDCDC;

  .caclulation-footer {
    align-items: end;
    margin-top: 2rem;
  }
}
</style>
