<template>
  <div>
    <!-- Save As -->
    <Modal>
      <template #default="{ hide }">
        <RequestVersionForm
          :request-id="requestId"
          :version="version"
          @cancel="hide"
          @confirm="(data) => { handleSaveAs(data); hide(); }"
        />
      </template>
      <template #handler="{ show }">
        <a-button
          class="ant-btn ant-btn-lg ant-btn-primary calculation-save-btn"
          @click="show"
        >
          {{ $t('Save As') }}
        </a-button>
      </template>
    </Modal>

    <!-- Overwrite -->
    <a-button
      class="ant-btn ant-btn-lg ant-btn-primary calculation-save-btn"
      :disabled="!simulationId"
      @click="handleOverwrite"
    >
      {{ $t('Overwrite') }}
    </a-button>

    <!-- Different Version -->
    <Modal>
      <template #default="{ hide }">
        <RequestVersionForm
          :is-new-version="true"
          :request-id="requestId"
          :version="version"
          @cancel="hide"
          @confirm="(data) => { handleSaveAs(data); hide(); }"
        />
      </template>
      <template #handler="{ show }">
        <a-button
          class="ant-btn ant-btn-lg ant-btn-primary calculation-save-btn"
          @click="show"
        >
          {{ $t('Different Ver.') }}
        </a-button>
      </template>
    </Modal>
    <a-button
      class="ant-btn ant-btn-lg ant-btn-primary calculation-save-btn"
      :disabled="isFetching && !results"
      @click="handlePdf"
    >
      {{ $t('PDF') }}
    </a-button>
  </div>
</template>

<i18n>
{
  "en": {
    "Save As": "Save As",
    "Overwrite": "Overwrite",
    "Different Ver.": "Different Ver.",
    "PDF": "PDF"
  },
  "ja": {
    "Save As": "Save As",
    "Overwrite": "Overwrite",
    "Different Ver.": "Different Ver.",
    "PDF": "PDF"
  }
}
</i18n>

<script>
import { mapState } from 'vuex';
import Modal from '@/components/Modal';
import RequestVersionForm from '@/views/simulations/components/calculation/RequestVersionForm';

export default {
  name: 'SaveForm',
  components: {
    Modal,
    RequestVersionForm,
  },
  props: {
    simulationId: {
      type: Number,
      default: null,
    },
    requestId: {
      type: String,
      default: null,
    },
    version: {
      type: Number,
      default: 1,
    },
  },
  emits: ['create', 'overwrite', 'generate-pdf'],
  data() {
    return {
      isLoading: false,
      size: 'large',
    };
  },
  computed: {
    ...mapState('simulation-search', ['isFetching', 'results']),
  },
  methods: {
    handleSaveAs(data) {
      this.$emit('create', data);
    },
    handleOverwrite() {
      this.$emit('overwrite');
    },
    handlePdf() {
      this.$emit('generate-pdf');
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .calculation-save-btn {
  margin-left: 0.8rem;
  margin-bottom: 0.8rem;
  min-width: 145px;
}
</style>
