<template>
  <div
    id="calculation-response"
    class="page"
  >
    <section class="hero is-banner is-primary">
      <div class="section">
        <div class="container">
          <h3 class="is-4 title">
            {{ $t('Simulation & Calculation Response') }}
          </h3>
        </div>
      </div>
      <div class="response-menu-section">
        <div class="container">
          <div class="columns is-align-bottom is-gapless">
            <div class="column is-narrow">
              <div class="response-menu-outer">
                <ul class="response-menu">
                  <li
                    v-for="r in menu"
                    :key="r.name"
                    class="response-menu-item"
                  >
                    <a
                      :href="`#${r.name}`"
                      :class="{ active: isActive(r.name) }"
                      @click.stop.prevent="setActive(r.name)"
                    >
                      <LoadingOutlined
                        v-if="r.name === 'search'"
                        v-show="isFetching && !results"
                        class="m-r"
                      />
                      {{ $t(r.label) }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="m-t-3x">
      <div
        v-show="isActive('calculation')"
        class="calculation"
      >
        <slot name="calculation" />
      </div>
      <div
        v-show="isActive('search')"
        class="search"
      >
        <slot name="search" />
      </div>
    </section>
  </div>
</template>

<i18n>
{
  "en": {
    "Simulation & Calculation Response": "Simulation & Calculation Response",
    "search-empty": "Please enter an address"
  },
  "ja": {
    "Simulation & Calculation Response": "Simulation & Calculation Response",
    "search-empty": "アドレスを入力してください"
  }
}
</i18n>

<script>
import { mapState } from 'vuex';
import animateScrollTo from 'animated-scroll-to';
import LoadingOutlined from '@ant-design/icons-vue/LoadingOutlined';

export default {
  name: 'SimulationResponse',
  components: {
    LoadingOutlined,
  },
  props: {
    searchOnly: {
      type: Boolean,
    },
  },
  emits: ['toggle'],
  data() {
    return {
      error: '',
      activeItem: 'calculation',
    };
  },
  computed: {
    ...mapState('simulation-search', ['isFetching', 'results']),
    menu() {
      const menu = [{
        name: 'search',
        label: 'Simulation',
      }];

      if (this.searchOnly === false) {
        menu.unshift({
          name: 'calculation',
          label: 'Calculation',
        });
      }

      return menu;
    },
  },
  watch: {
    searchOnly: {
      immediate: true,
      handler(nv) {
        this.activeItem = nv === true ? 'search' : 'calculation';

        this.$nextTick(() => {
          setTimeout(() => {
            animateScrollTo(document.querySelector('#calculation-response'), {
              elementToScroll: document.querySelector('body'),
            });
          }, 100);
        });
      },
    },
  },
  methods: {
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
      this.$emit('toggle', menuItem);
    },
  },
};
</script>

<style lang="scss" scoped>
.response-menu-section {
  background-color: $primary;
  padding-bottom: 0;
  align-items: flex-end;
}

.response-menu {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 0 !important;
}

.response-menu-item {
  a {
    display: block;
    padding: 8px 15px;
    color: rgba(255, 255, 255, 0.7);
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
    font-weight: bold;
    font-size: $size-6;
    white-space: nowrap;
    transition: opacity .3s;

    &:hover {
      opacity: 0.3;
    }

    &.active {
      color: $primary !important;
      background-color: $white;
      opacity: 1;
    }
  }
}
</style>
