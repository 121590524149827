<template>
  <div class="columns is-mobile is-multiline is-vcentered">
    <RoomTypeFields
      v-for="(roomType, index) in form"
      :key="index"
      :value="roomType"
      :index="index"
      class="column is-12"
      @update="(val) => { handleUpdate(val, index) }"
      @removed="handleRemove"
    />
    <div class="column is-12">
      <a-button
        type="primary"
        :size="size"
        class="btn-add-room button-secondary-outline"
        @click="() => handleUpdate()"
      >
        <i
          class="el-icon-plus"
        /> {{ $t('Add Room') }}
      </a-button>
    </div>
  </div>
</template>

<script>
import RoomTypeFields from '@/views/simulations/components/RoomTypeFields';

export default {
  name: 'RoomTypesForm',
  components: {
    RoomTypeFields,
  },
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  emits: ['update', 'removed'],
  data() {
    return {
      size: 'large',
      form: [{}],
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        if (nv && nv.length > 0) {
          this.form = [...nv];
        }
      },
    },
  },
  methods: {
    handleUpdate(roomType, index = null) {
      if (index !== null) {
        this.form[index] = roomType;
      } else {
        this.form.push({});
      }

      this.$emit('update', [...this.form]);
    },
    handleRemove(roomType, index) {
      this.form.splice(index, 1);

      if (this.form?.length === 0) {
        this.form.push({});
      }

      this.$emit('update', [...this.form]);
      this.$emit('removed', roomType);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .btn-add-room {
  margin-left: 36px;

  i {
    font-weight: bold;
    margin-right: 5px;
  }
}
</style>
