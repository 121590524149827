<template>
  <div>
    <div class="is-flex room-type-wrapper">
      <div class="is-flex-shrink-0">
        <a-checkbox
          v-model:checked="form.isChecked"
          class="room-checkbox"
          @change="handleUpdate"
        />
      </div>
      <div
        class="is-flex-grow-1 is-flex-shrink-1 room-fields"
        :class="{ 'is-checked': form.isChecked }"
      >
        <div class="columns is-mobile is-multiline">
          <div class="column is-10 simulation-fields-wrapper">
            <div class="columns is-mini-gap is-multiline is-vcentered">
              <div class="column is-6">
                <div class="columns is-mini-gap is-multiline">
                  <a-form-item
                    class="column is-6"
                    :label="$t('Room type name')"
                  >
                    <a-input
                      v-model:value="form.name"
                      :size="size"
                      type="text"
                      @change="handleUpdate"
                    />
                  </a-form-item>

                  <a-form-item
                    class="column is-3"
                    :label="$t('No. of rooms')"
                  >
                    <a-input-number
                      v-model:value="form.roomCount"
                      :size="size"
                      @change="handleUpdate"
                    />
                  </a-form-item>

                  <a-form-item
                    class="column is-3"
                    :label="$t('Capacity')"
                  >
                    <a-input-number
                      v-model:value="form.capacity"
                      :size="size"
                      :placeholder="$t('ppl')"
                      @change="handleUpdate"
                    />
                  </a-form-item>
                </div>
              </div>
              <div class="column is-6">
                <div class="columns is-mini-gap is-multiline">
                  <a-form-item
                    class="column is-3"
                    :label="$t('No. of dbl')"
                  >
                    <a-input-number
                      v-model:value="form.doubleCount"
                      :size="size"
                      @change="handleUpdate"
                    />
                  </a-form-item>

                  <a-form-item
                    class="column is-3"
                    :label="$t('No. of dbl futon')"
                  >
                    <a-input-number
                      v-model:value="form.doubleFutonCount"
                      :size="size"
                      @change="handleUpdate"
                    />
                  </a-form-item>

                  <a-form-item
                    class="column is-3"
                    :label="$t('No. of sgl')"
                  >
                    <a-input-number
                      v-model:value="form.singleCount"
                      :size="size"
                      @change="handleUpdate"
                    />
                  </a-form-item>

                  <a-form-item
                    class="column is-3"
                    :label="$t('No. of sgl futon')"
                  >
                    <a-input-number
                      v-model:value="form.singleFutonCount"
                      :size="size"
                      @change="handleUpdate"
                    />
                  </a-form-item>
                </div>
              </div>
              <div class="column is-6">
                <div class="columns is-mini-gap is-multiline">
                  <a-form-item
                    class="column is-4"
                    :label="$t('Occupied area (room)')"
                  >
                    <a-input
                      v-model:value="form.occupiedRoomArea"
                      :size="size"
                      :placeholder="$t('sqm')"
                      type="number"
                      @change="handleUpdate"
                    />
                  </a-form-item>

                  <a-form-item
                    class="column is-8"
                    :label="$t('Occupied area (privately occupied space)')"
                  >
                    <a-input
                      v-model:value="form.occupiedPrivateArea"
                      :size="size"
                      :placeholder="$t('sqm')"
                      type="number"
                      @change="handleUpdate"
                    />
                  </a-form-item>
                </div>
              </div>
              <div class="column is-6">
                <div class="columns is-mini-gap is-multiline">
                  <a-form-item
                    class="column is-3"
                    :label="$t('OCC adjustments')"
                  >
                    <a-input
                      v-model:value="form.occAdjustments"
                      :size="size"
                      placeholder="%"
                      type="number"
                      @change="handleUpdate"
                    />
                  </a-form-item>

                  <a-form-item
                    class="column is-3"
                    :label="$t('DOR adjustments')"
                  >
                    <a-input
                      v-model:value="form.dorAdjustments"
                      :size="size"
                      :placeholder="$t('ppl')"
                      type="number"
                      @change="handleUpdate"
                    />
                  </a-form-item>

                  <a-form-item
                    class="column is-3"
                    :label="$t('F&B adjustments')"
                  >
                    <a-input-number
                      v-model:value="form.fbAdjustments"
                      :size="size"
                      placeholder="JPY"
                      @change="handleUpdate"
                    />
                  </a-form-item>

                  <a-form-item
                    class="column is-3"
                    :label="$t('Other adjustments')"
                  >
                    <a-input-number
                      v-model:value="form.otherAdjustments"
                      :size="size"
                      placeholder="JPY"
                      @change="handleUpdate"
                    />
                  </a-form-item>
                </div>
              </div>
            </div>
          </div>
          <div class="column">
            <Modal width="760px">
              <template #default="{ hide }">
                <RoomAddonIndex
                  :room-type="form"
                  @submit="(addons) => { hide(); handleRoomAddonIndexUpdate(addons); }"
                  @cancel="hide"
                />
              </template>
              <template #handler="{ show }">
                <a
                  class="is-block mb-4"
                  @click.stop.prevent="show"
                >
                  <i class="el-icon-plus has-text-weight-bold" />
                  <span class="ml-2">{{ $t('Room add-ons index') }}</span>
                </a>
              </template>
            </Modal>
            <a
              class="has-text-danger is-block"
              @click.stop.prevent="handleRemove"
            >
              <DeleteFilled />
              <span class="ml-2">{{ $t('Delete') }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DeleteFilled } from '@ant-design/icons-vue';
import Modal from '@/components/ModalWide';
import RoomAddonIndex from '@/views/simulations/components/addon-index/RoomAddonIndex';

export default {
  name: 'RoomTypesFields',
  components: {
    DeleteFilled,
    Modal,
    RoomAddonIndex,
  },
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    index: {
      type: Number,
      required: true,
    },
  },
  emits: ['update', 'removed'],
  data() {
    return {
      size: 'large',
      form: {
        id: undefined,
        name: '',
        roomCount: undefined,
        capacity: undefined,
        doubleCount: undefined,
        doubleFutonCount: undefined,
        singleCount: undefined,
        singleFutonCount: undefined,
        occupiedRoomArea: undefined,
        occupiedPrivateArea: undefined,
        occAdjustments: undefined,
        dorAdjustments: undefined,
        fbAdjustments: undefined,
        otherAdjustments: undefined,
        isChecked: false,
        roomAddonIndexes: [],
      },
    };
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(nv) {
        if (nv) {
          const keys = Object.keys(this.form);
          keys.forEach((k) => {
            this.form[k] = nv[k];
          });

          this.form.roomAddonIndexes = nv.roomAddonIndexes || [];
        }
      },
    },
  },
  methods: {
    handleUpdate() {
      this.$emit('update', this.form, this.index);
    },
    handleRemove() {
      this.$emit('removed', this.form, this.index);
    },
    handleRoomAddonIndexUpdate(addons) {
      this.form.roomAddonIndexes = addons;
      this.handleUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
.room-fields::v-deep {
  border: 1px solid #DCDCDC;
  border-radius: 5px;
  padding: 15px;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }

  &.is-checked {
    border-color: #888888;
  }

  .ant-form-item-label label {
    font-size: 13px;
  }

  .ant-input-number {
    width: 100%;
  }

  input[type=number],
  .ant-input-number-input {
    text-align: right;
  }
}

.room-checkbox::v-deep {
  margin-left: 0.5rem;
  margin-right: 0.75rem;

  .ant-checkbox-inner {
    border-color: #DCDCDC;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: #888888;
    background-color: #DCDCDC;
  }
}
</style>
