<template>
  <div>
    <div class="is-flex room-type-wrapper">
      <div class="is-flex-shrink-0">
        <a-checkbox
          :checked="value.isChecked"
          class="room-checkbox"
          disabled
        />
      </div>
      <div
        class="is-flex-grow-1 is-flex-shrink-1 room-calculation"
        :class="{ 'is-checked': value.isChecked }"
      >
        <div class="columns is-multiline">
          <div class="column is-12">
            <h4 class="has-text-primary has-text-weight-bold is-size-5">
              {{ value.name }}
            </h4>
          </div>
          <div class="column is-3">
            <div class="mt-2 room-calculation-field">
              <label for="adr">{{ $t('ADR') }}</label>
              <a-input-number
                id="adr"
                v-model="form.adr"
                :value="formatInput(form.adr)"
                :size="size"
                @change="(v) => delayVarChange('adr', v)"
              />
              <span>JPY</span>
            </div>
            <div class="room-calculation-field">
              <label for="occ">{{ $t('OCC') }}</label>
              <a-input-number
                id="occ"
                v-model="form.occ"
                :value="formatInput(form.occ * 100)"
                :size="size"
                @change="(v) => delayVarChange('occ', v)"
              />
              <span>%</span>
            </div>
            <div class="room-calculation-field">
              <label for="los">{{ $t('LOS') }}</label>
              <a-input-number
                id="los"
                v-model="form.los"
                :value="formatInput(form.los)"
                :size="size"
                @change="(v) => delayVarChange('los', v)"
              />
              <span>{{ $t('nights') }}</span>
            </div>
            <div class="room-calculation-field">
              <label for="dor">{{ $t('DOR') }}</label>
              <a-input-number
                id="dor"
                v-model="form.dor"
                :value="formatInput(form.dor)"
                :size="size"
                @change="(v) => delayVarChange('dor', v)"
              />
              <span>{{ $t('ppl') }}</span>
            </div>
          </div>
          <div class="column is-9 m-0 p-0">
            <div class="columns is-multiline">
              <div class="column is-4">
                <table class="calculation-table is-fullwidth table">
                  <tr>
                    <td>{{ $t('Occupied area (room total)') }}</td>
                    <td>{{ $filters.number(result.occupiedRoomArea) + ' sqm' }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('Occupied area (privately occupied space total)') }}</td>
                    <td>{{ $filters.number(result.occupiedPrivateArea) + ' sqm' }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('Occupied area') }}</td>
                    <td>{{ $filters.number(result.occupiedArea) + ' sqm' }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('Capacity') }}</td>
                    <td>{{ $filters.number(result.capacity) + ' ppl' }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('Checkouts') }}</td>
                    <td>{{ $filters.number(result.checkouts) + ' c/o' }}</td>
                  </tr>
                </table>
              </div>
              <div class="column is-4">
                <table class="calculation-table is-fullwidth table">
                  <tr>
                    <td>{{ $t('Gross annual sales') }}</td>
                    <td>{{ format(result.grossAnnualSales) + ' JPY' }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('Gross annual sales w/o tax') }}</td>
                    <td>{{ format(result.grossAnnualSalesNoTax) + ' JPY' }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('Monthly sales') }}</td>
                    <td>{{ format(result.monthlySales) + ' JPY' }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('Monthly sales w/o tax') }}</td>
                    <td>{{ format(result.monthlySalesNoTax) + ' JPY' }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('RevPAR') }}</td>
                    <td>{{ format(result.revPAR) + ' JPY' }}</td>
                  </tr>
                </table>
              </div>
              <div class="column is-4">
                <table class="calculation-table is-fullwidth table">
                  <tr>
                    <td>{{ $t('Estimated room nights (room only)') }}</td>
                    <td>
                      {{ $filters.number(result.estimatedRoomNightsRoomOnly) }} {{ $t('nights') }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t('Estimated room nights (meal & stay)') }}</td>
                    <td>
                      {{ $filters.number(result.estimatedRoomNightsMealAndStay) }}
                      {{ $t('nights') }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t('Estimated room nights (total)') }}</td>
                    <td>
                      {{ $filters.number(result.estimatedRoomNightsTotal) }}
                      {{ $t('nights') }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t('Rooms available') }}</td>
                    <td>{{ $filters.number(result.roomsAvailable) }} {{ $t('rooms') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('Total estimated OCC') }}</td>
                    <td>{{ $filters.number(result.estimatedOCC * 100) + '%' }}</td>
                  </tr>
                </table>
              </div>
              <div class="column is-4">
                <table class="calculation-table is-fullwidth table">
                  <tr>
                    <td>{{ $t('Occupied space per room') }}</td>
                    <td>{{ $filters.number(result.occupiedSpacePerRoom) + ' sqm' }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('ADR SQM') }}</td>
                    <td>{{ $filters.number(result.adrSqm) + ' JPY' }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('RevPAR SQM') }}</td>
                    <td>{{ $filters.number(result.revPARsqm) + ' JPY' }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('RevPAR SQM w/o tax') }}</td>
                    <td>{{ $filters.number(result.revPARsqmNoTax) + ' JPY' }}</td>
                  </tr>
                </table>
              </div>
              <div class="column is-4">
                <table class="calculation-table is-fullwidth table">
                  <tr>
                    <td>{{ $t('ARPP') }}</td>
                    <td>{{ format(result.arpp) + ' JPY' }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('ARPP w/o tax') }}</td>
                    <td>{{ format(result.arppNoTax) + ' JPY' }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('2PAX ARPP') }}</td>
                    <td>{{ format(result.twoPaxARPP) + ' JPY' }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('2PAX ARPP w/o tax') }}</td>
                    <td>{{ format(result.twoPaxARPPNoTax) + ' JPY' }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('MIN ARPP') }}</td>
                    <td>{{ format(result.minARPP) + ' JPY' }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import number from '@/filters/number';

export default {
  name: 'ResultRoomType',
  components: {
  },
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    index: {
      type: Number,
      required: true,
    },
  },
  emits: ['update'],
  data() {
    return {
      size: 'large',
      form: {
        adr: undefined,
        occ: undefined,
        los: undefined,
        dor: undefined,
      },
    };
  },
  computed: {
    result() {
      return this.value?.result || {};
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(nv) {
        if (nv && nv.vars) {
          Object
            .keys(this.form)
            .forEach((key) => {
              this.form[key] = nv.vars[key] || 0;
            });
        }
      },
    },
  },
  methods: {
    delayVarChange: debounce(function delayVarChange(key, value) {
      this.handleVarChange(key, value);
    }, 300),
    handleVarChange(key, value) {
      const newValue = key === 'occ' ? value / 100 : value;
      const current = this.form[key];

      if (newValue === current) {
        return;
      }

      this.form[key] = newValue;

      this.$emit('update', this.form, this.index);
    },
    format(value) {
      return number(Math.trunc(value));
    },
    formatInput(value) {
      return number(value, { useGrouping: false });
    },
  },
};
</script>

<style lang="scss" scoped>
.room-calculation::v-deep {
  border: 1px solid #DCDCDC;
  border-radius: 5px;
  padding: 15px;

  &.is-checked {
    border-color: #888888;
  }

  .ant-input-number {
    min-width: 100px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .ant-input-number-input {
    text-align: right;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }

  .room-calculation-field {
    display: flex;
    align-items: center;
    margin-bottom: 0.8rem;

    label {
      width: 35px;
    }

    label,
    span {
      font-size: 14px;
    }
  }
}

.room-checkbox::v-deep {
  margin-left: 0.5rem;
  margin-right: 0.75rem;

  .ant-checkbox-inner {
    border-color: #DCDCDC;
  }

  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner,
  .ant-checkbox-checked .ant-checkbox-inner {
    &::after{
      border-color: #FFFFFF;
    }

    border-color: #888888 !important;
    background-color: #DCDCDC;
  }
}
</style>
