<template>
  <a-form
    ref="form"
    :model="form"
    :rules="rules"
    class="addon-index-form columns is-mini-gap is-multiline"
  >
    <div class="column is-12">
      <h2 class="ant-modal-title has-text-primary has-text-weight-bold is-size-4">
        {{ $t('Room Add-ons Index') }}
      </h2>
    </div>
    <div class="column is-12 mt-5">
      <div class="table-container">
        <table class="is-fullwidth table">
          <thead>
            <tr>
              <th>{{ $t('Features') }}</th>
              <th>{{ $t('Index') }}</th>
              <th>{{ $t('ADR Lift') }}</th>
              <th>{{ $t('Calculated Amount') }}</th>
              <th>{{ $t('DOR') }}</th>
              <th>{{ $t('LOS') }}</th>
              <th>{{ $t('OCC') }}</th>
              <th>{{ }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(addon, listIndex) in form.addons"
              :key="listIndex"
            >
              <td>
                <a-select
                  v-model:value="addon.roomAddonId"
                  :placeholder="$t('Please select')"
                  style="min-width: 180px;"
                  :size="size"
                  @change="(v, opts) => handleSelectFeature(listIndex, opts.data)"
                >
                  <a-select-option
                    v-for="(v, key) in features"
                    :key="key"
                    :data="v"
                    :value="v.id"
                  >
                    {{ $t(v.feature) }}
                  </a-select-option>
                </a-select>
              </td>
              <td>
                <a-input
                  v-model:value="addon.index"
                  :size="size"
                  type="text"
                  class="index-input"
                  @change="(e) => delayIndexChange(listIndex, e.target.value)"
                />
              </td>
              <td>{{ addon.adrLift ? `${$filters.number(addon.adrLift)} JPY` : '-' }}</td>
              <td>
                {{
                  addon.calculatedAmount
                    ? `${$filters.number(addon.calculatedAmount)} JPY`
                    : '-'
                }}
              </td>
              <td>{{ addon.dor ?? '-' }}</td>
              <td>{{ addon.los ?? '-' }}</td>
              <td>{{ addon.occ ?? '-' }}</td>
              <td>
                <DeleteFilled
                  class="has-text-danger"
                  @click.stop.prevent="removeAddon(listIndex)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="column is-12 mt-5">
      <a-button
        type="primary"
        :size="size"
        class="btn-add-room button-secondary-outline"
        @click="addAddon"
      >
        <i
          class="el-icon-plus has-text-weight-bold mr-2"
        /> {{ $t('Add Add-on') }}
      </a-button>
    </div>
    <div class="column has-text-right is-12 mt-5">
      <a-button
        :disabled="isSubmitting"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px;"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="isSubmitting"
        :size="size"
        style="min-width: 115px;"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<script>
import debounce from 'lodash/debounce';
import { DeleteFilled } from '@ant-design/icons-vue';

export default {
  name: 'RoomAddonIndex',
  components: {
    DeleteFilled,
  },
  props: {
    roomType: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  emits: ['cancel', 'submit'],
  data() {
    return {
      size: 'large',
      isSubmitting: false,
      features: [],
      form: {
        addons: [{ index: 0 }],
      },
      rules: {},
    };
  },
  watch: {
    roomType: {
      immediate: true,
      deep: true,
      handler(nv) {
        if (nv && nv.roomAddonIndexes) {
          const addons = nv.roomAddonIndexes.map((addon) => ({
            roomAddonId: addon.roomAddonId,
            index: addon.index,
            adrLift: addon.adrLift,
            dor: addon.dor,
            los: addon.los,
            occ: addon.occ,
            calculatedAmount: addon.adrLift * addon.index,
          }));

          this.form.addons = addons.length > 0 ? addons : [{ index: 0 }];
        }
      },
    },
  },
  created() {
    this.setFeatures();
  },
  methods: {
    setFeatures() {
      this.$store
        .dispatch('simulations/listRoomAddons', { infinite: true })
        .then((res) => {
          this.features = res.data;
        });
    },
    handleSelectFeature(listIndex, data) {
      const addon = this.form.addons[listIndex];

      if (addon) {
        const {
          id, adrLift, dor, los, occ,
        } = data;

        this.form.addons[listIndex] = {
          ...addon,
          calculatedAmount: adrLift * addon.index,
          roomAddonId: id,
          adrLift,
          dor,
          los,
          occ,
        };
      }
    },
    delayIndexChange: debounce(function (listIndex, value) {
      this.handleIndexChange(listIndex, value);
    }, 300),
    handleIndexChange(listIndex, index) {
      const addon = this.form.addons[listIndex];

      if (addon) {
        const adrLift = addon.adrLift || 0;
        const indexVal = Number(index) || 0;

        this.form.addons[listIndex] = {
          ...addon,
          index,
          calculatedAmount: adrLift * indexVal,
        };
      }
    },
    async handleSubmit() {
      try {
        await this.$refs.form.validate();

        const addons = this.form.addons.filter((addon) => addon.roomAddonId);

        this.$emit('submit', addons);
        this.resetFields();
      } catch (e) {
        console.log(e);
      }
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
    addAddon() {
      this.form.addons.push({
        roomAddonId: undefined,
        index: 0,
        adrLift: undefined,
        dor: undefined,
        los: undefined,
        occ: undefined,
        calculatedAmount: undefined,
      });
    },
    removeAddon(index) {
      this.form.addons.splice(index, 1);

      if (this.form.addons?.length === 0) {
        this.addAddon();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.addon-index-form::v-deep {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  .index-input {
    width: 50px;
  }

  .table td,
  .table th {
    vertical-align: middle;
  }

  .table th {
    padding-right: 0;
  }
}
</style>
