<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="m-b-4x">
            <router-link
              :to="{ name: 'simulations-list' }"
              class="has-text-weight-bold has-text-white"
            >
              <IconBase
                width="14"
                height="12"
                icon-name="back"
                class="m-b-0 m-r-2x"
              >
                <IconBack />
              </IconBase>
              <span>{{ $t('Simulations') }}</span>
            </router-link>
          </div>
          <h2 class="is-3 title">
            {{ $t('Simulation Calculation') }}
          </h2>
        </div>
      </div>
    </section>
    <Form
      :request="simulation"
    />
  </div>
</template>

<i18n src="@/locales/components/simulations.json"></i18n>

<script>
import IconBack from '@/components/icons/IconBack';
import Form from '@/views/simulations/components/Form';
import methods from '@/views/simulations/mixins/methods';

export default {
  name: 'ShowSimulation',
  components: {
    Form,
    IconBack,
  },
  mixins: [methods],
  data() {
    return {
      simulation: undefined,
    };
  },
  watch: {
    $route(route) {
      if (route.name === 'simulations-show') {
        this.fetchData();
      }
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');

      this.getSimulation()
        .then((data) => {
          this.simulation = data;
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
  },
};
</script>
