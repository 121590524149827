<template>
  <div class="section">
    <a-form
      ref="form"
      :model="form"
      :rules="rules"
      class="container simulation-form"
    >
      <h2 class="form-title mb-4 mt-0">
        {{ $t('Information') }}
      </h2>
      <div class="columns is-mobile is-multiline">
        <div class="column is-10">
          <div class="columns is-mini-gap is-multiline is-vcentered simulation-fields-wrapper">
            <div class="column is-4">
              <div class="columns is-mini-gap is-multiline">
                <a-form-item
                  class="column is-6"
                  :label="$t('Postal code')"
                  name="postalCode"
                >
                  <a-input
                    v-model:value="form.postalCode"
                    :size="size"
                    type="text"
                  />
                </a-form-item>
                <a-form-item
                  class="column is-6"
                  :label="$t('Prefecture')"
                  name="prefecture"
                >
                  <InputPrefecture
                    v-model:value="form.prefecture"
                    :country-code="form.country"
                    @change="handlePrefectureChange"
                  />
                </a-form-item>
                <a-form-item
                  class="column is-12"
                  :label="$t('Address')"
                  name="address"
                >
                  <InputAddress
                    v-model:value="form.address"
                    :country="form.country"
                    :size="size"
                    @change="handleAddressChanged"
                  />
                </a-form-item>
              </div>
            </div>
            <div class="column is-4">
              <div class="columns is-mini-gap is-multiline">
                <a-form-item
                  class="column is-6"
                  :label="$t('Area small')"
                  name="areaSmall"
                >
                  <InputArea
                    v-model:value="form.areaSmall"
                    field="small"
                    :prefecture="areaPrefecture"
                    :size="size"
                    @change="() => setSpdb()"
                  />
                </a-form-item>
                <a-form-item
                  class="column is-6"
                  :label="$t('Area detail')"
                  name="areaDetail"
                >
                  <InputArea
                    v-model:value="form.areaDetail"
                    field="detail"
                    :prefecture="areaPrefecture"
                    :size="size"
                    @change="() => setSpdb()"
                  />
                </a-form-item>
                <a-form-item
                  class="column is-6 meal-form-item"
                  label=" "
                  name="hasBreakfast"
                >
                  <a-checkbox
                    v-model:checked="form.hasBreakfast"
                    @change="() => setSpdb()"
                  >
                    {{ $t('Breakfast included') }}
                  </a-checkbox>
                </a-form-item>
                <a-form-item
                  class="column is-6 meal-form-item"
                  label=" "
                  name="hasDinner"
                >
                  <a-checkbox
                    v-model:checked="form.hasDinner"
                    @change="() => setSpdb()"
                  >
                    {{ $t('Dinner included') }}
                  </a-checkbox>
                </a-form-item>
              </div>
            </div>
            <div class="column is-2">
              <div class="columns is-mini-gap is-multiline">
                <a-form-item
                  class="column is-12"
                  :label="$t('Request ID')"
                  name="requestId"
                >
                  <a-input
                    v-model:value="form.requestId"
                    :size="size"
                    type="text"
                  />
                </a-form-item>
                <a-form-item
                  class="column is-12"
                  :label="$t('Version ID')"
                  name="version"
                >
                  <a-input
                    v-model:value="form.version"
                    :size="size"
                    type="text"
                    disabled
                  />
                </a-form-item>
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <Modal>
            <template #default="{hide}">
              <GeneralAddonIndex
                :simulation="form"
                @submit="(addons) => { hide(); handleGeneralAddonIndexUpdate(addons); }"
                @cancel="hide"
              />
            </template>
            <template #handler="{ show }">
              <a
                class="btn-add-addon"
                @click.stop.prevent="show"
              >
                <i class="el-icon-plus has-text-weight-bold" />
                <span class="ml-2">{{ $t('General add-ons index') }}</span>
              </a>
            </template>
          </Modal>
        </div>
      </div>

      <h2 class="form-title mt-6">
        {{ $t('Room') }}
      </h2>
      <RoomTypesForm
        :value="form.roomTypes"
        @update="handleRoomTypeUpdate"
        @removed="handleRoomTypeRemove"
      />

      <div class="columns is-vcentered">
        <div class="column has-text-centered is-12 m-t-4x">
          <a-button
            class="ant-btn ant-btn-lg ant-btn-primary m-r-4x simulate-btn"
            @click="handleSearch"
          >
            {{ $t('Simulation Only') }}
          </a-button>
          <a-button
            class="ant-btn ant-btn-lg ant-btn-primary simulate-btn"
            @click="handleSearchAndCalculate"
          >
            {{ $t('Simulation & Calculation') }}
          </a-button>
        </div>
      </div>
    </a-form>
  </div>
  <Response
    v-if="showResponse"
    id="calculation-response"
    :search-only="searchOnly"
    @toggle="handleResponseToggle"
  >
    <template #calculation>
      <Calculation
        v-if="!searchOnly"
        :request="form"
        :removed-room-types="removedRoomTypes"
        @simulation-update="updateForm"
      />
    </template>
    <template #search>
      <SearchMap
        :is-map-ready="isMapReady"
        :request="form"
        :map-options="{ scrollWheelZoom: false }"
        @address-update="handleMapAddressUpdate"
      />
    </template>
  </Response>
</template>

<i18n src="@/locales/components/simulations.json"></i18n>

<i18n>
{
  "en": {
    "Information": "Information",
    "Prefecture": "Prefecture",
    "Breakfast included": "Breakfast included",
    "Dinner included": "Dinner included",
    "Simulation Only": "Simulation Only",
    "Simulation & Calculation": "Simulation & Calculation",
    "General add-ons index": "General add-ons index",
    "address-required": "Address is required",
    "invalid-address": "Please select a formatted address from the auto suggest drop-down",
  },
  "ja": {
    "Information": "情報",
    "Prefecture": "都道府県",
    "Breakfast included": "Breakfast included",
    "Dinner included": "Dinner included",
    "Simulation Only": "Simulation Only",
    "Simulation & Calculation": "Simulation & Calculation",
    "General add-ons index": "General add-ons index",
    "address-required": "アドレスが必要です",
    "invalid-address": "自動プロジェクタードロップダウンからフォーマットされたアドレスを選択してください",
  }
}
</i18n>

<script>
import { getLocale } from '@/utils/locale';
import Modal from '@/components/ModalWide';
import { sanitizeYears, spdbBaseQuery } from '@/views/simulations/components/utils';
import { getAreaPrefecture } from '@/plugins/countriesStatesHelper';
import InputPrefecture from '@/components/InputState';
import InputAddress from '@/views/simulations/components/InputAddress';
import InputArea from '@/views/simulations/components/InputArea';
import RoomTypesForm from '@/views/simulations/components/RoomTypesForm';
import Response from '@/views/simulations/components/Response';
import SearchMap from '@/views/simulations/components/SearchMap';
import Calculation from '@/views/simulations/components/Calculation';
import GeneralAddonIndex from '@/views/simulations/components/addon-index/GeneralAddonIndex';
import { DEFAULT_COUNTRY, DEFAULT_SORT, DEFAULT_SIZE } from '@/config/simulations';

export default {
  name: 'SimulationForm',
  components: {
    Modal,
    InputPrefecture,
    InputAddress,
    InputArea,
    GeneralAddonIndex,
    RoomTypesForm,
    Response,
    SearchMap,
    Calculation,
  },
  props: {
    request: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      isMapReady: false,
      showResponse: false,
      searchOnly: true,
      removedRoomTypes: [],
      form: {
        id: undefined,
        requestId: undefined,
        version: 1,
        postalCode: undefined,
        prefecture: undefined,
        address: undefined,
        country: DEFAULT_COUNTRY,
        placeId: undefined,
        coordinates: undefined,
        areaSmall: undefined,
        areaDetail: undefined,
        hasBreakfast: true,
        hasDinner: true,
        remarks: undefined,
        size: undefined,
        sort: undefined,
        language: undefined,
        roomTypes: [],
        generalAddonIndexes: [],
        calculationAdjustments: {},
        spdb: undefined,
        occAverages: undefined,
        occBeforeValues: undefined,
      },
      rules: {
        requestId: [
          {
            required: true,
            message: this.$t('Request ID is required'),
          },
        ],
      },
      size: 'large',
    };
  },
  computed: {
    areaPrefecture() {
      return getAreaPrefecture(this.form.prefecture);
    },
  },
  watch: {
    request: {
      immediate: true,
      deep: true,
      handler(nv) {
        if (nv) {
          this.updateForm(nv);
        }
      },
    },
  },
  async created() {
    await this.$store.dispatch('google-map/load');
    this.isMapReady = true;
  },
  methods: {
    updateForm(data) {
      const keys = Object.keys(this.form);
      keys.forEach((k) => {
        this.form[k] = data[k];
      });

      this.removedRoomTypes = [];
    },
    handleAddressChanged(data) {
      if (data.address) {
        this.handleMapAddressUpdate(data);
      }
    },
    async handleSearch() {
      await this.handleSubmit(true);
    },
    async handleSearchAndCalculate() {
      await this.handleSubmit(false);
    },
    async handleSubmit(searchOnly) {
      try {
        await this.$refs.form.validate();

        const payload = this.getSearchPayload();

        if (!payload.placeId || !payload.coordinates) {
          this.$message.error(this.$t('invalid-address'));

          return;
        }

        this.searchOnly = Boolean(searchOnly);

        if (this.searchOnly === false) {
          this.$store.commit('SHOW_FULLSCREEN_LOADER');
          await this.setCalculationAdjustments();
          await this.setPreCalculationValues();
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        }

        this.showResponse = true;

        await this.$store.dispatch('simulation-search/search', payload);
      } catch (e) {
        console.log(e);
        this.$store.commit('HIDE_FULLSCREEN_LOADER');
      }
    },
    getSearchPayload() {
      // defaults
      this.form.language = this.form.language || getLocale();
      this.form.size = this.form.size || DEFAULT_SIZE;
      this.form.sort = this.form.sort || DEFAULT_SORT;

      // eslint-disable-next-line no-unused-vars
      const {
        roomTypes, generalAddonIndexes, calculationAdjustments, ...payload
      } = this.form;

      return payload;
    },
    async handlePrefectureChange() {
      const occAverages = await this.getPrefectureOccAverages();

      Object.assign(this.form, {
        areaSmall: '',
        areaDetail: '',
        occAverages,
      });
    },
    handleMapAddressUpdate(data) {
      if (!data) {
        this.form.address = null;
        this.form.placeId = null;
        this.form.coordinates = null;

        return;
      }

      this.form.address = data.address;
      this.form.placeId = data.placeId;
      this.form.coordinates = data.coordinates;
    },
    handleResponseToggle(view) {
      if (view === 'search') {
        const [lat, lng] = this.form.coordinates ? this.form.coordinates.split(',') : [];

        this.$store.commit('google-map/SET_MAP_CENTER', { lat, lng });
      }
    },
    handleRoomTypeUpdate(roomTypes) {
      this.form.roomTypes = [...roomTypes];
    },
    handleRoomTypeRemove(roomType) {
      const { id } = roomType;

      if (id) {
        this.removedRoomTypes.push(id);
      }
    },
    handleGeneralAddonIndexUpdate(addons) {
      this.form.generalAddonIndexes = addons;
    },
    async setCalculationAdjustments() {
      this.form.calculationAdjustments = await this.getCalculationAdjustments();
    },
    async setPreCalculationValues() {
      const [spdb, occAverages, occBeforeValues] = await Promise.all([
        this.getSpdb(),
        this.getPrefectureOccAverages(),
        this.getOccBeforeValues(),
      ]);

      Object.assign(this.form, {
        occAverages,
        occBeforeValues,
        spdb,
      });
    },
    async getCalculationAdjustments() {
      const adjustments = this.form.calculationAdjustments || {};

      if (adjustments && Object.keys(adjustments).length > 0) {
        return adjustments;
      }

      const { data } = await this.$store.dispatch('simulations/getCalculationAdjustment');

      return data;
    },
    async getSpdb() {
      const adj = this.form.calculationAdjustments || {};
      const query = {
        ...spdbBaseQuery(this.form, adj.pastReservationsInYear),
        hasBreakfast: this.form.hasBreakfast,
        hasDinner: this.form.hasDinner,
      };

      const { data } = await this.$store.dispatch('simulation-spdb/get', query);

      return {
        areaSqm: data.areaSqm,
        supposedAreaInventory: data.supposedAreaInventory,
        prefectureSupposedInventories: data.prefectureSupposedInventories,
        totalReservations: data.totalReservations,
        rtNumberOfNights: data.totalNights,
        areaADR: data.totalAmount / data.totalNights,
        areaRoomOnly: data.areaRoomOnly,
        areaMealFull: data.areaMealFull,
        areaMealBreakfast: data.areaMealBreakfast,
        areaMealDinner: data.areaMealDinner,
      };
    },
    async setSpdb() {
      const data = await this.getSpdb();
      this.form.spdb = data;
    },
    async getPrefectureOccAverages() {
      const adj = this.form.calculationAdjustments || {};
      const query = {
        years: sanitizeYears(adj.pastReservationsInYear),
        prefecture: this.areaPrefecture,
      };

      const { data } = await this.$store.dispatch('simulations/prefectureOccAverages', query);

      return data;
    },
    async getOccBeforeValues() {
      const { data } = await this.$store.dispatch('simulations/occBefore');

      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
.simulation-form::v-deep {
  margin: 0 auto !important;

  .ant-form-item-label > label::after {
    content: '';
  }

  .simulation-fields-wrapper {
    padding-right: 20px;
    border-right: 1px solid #DCDCDC;
  }
}

.simulate-btn {
  min-width: 125px;
}

.meal-form-item::v-deep {
  .ant-form-item-label > label::after {
    content: ' '
  }

  .ant-checkbox-wrapper span {
    font-size: 13px;
  }

  .ant-checkbox-inner {
    border-color: #DCDCDC;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: #888888;
    background-color: #DCDCDC;
  }
}
</style>
