<template>
  <div>
    <a-select
      v-model:value="text"
      show-search
      :placeholder="placeholder"
      style="width: 100%"
      :default-active-first-option="false"
      :filter-option="false"
      :not-found-content="null"
      :size="size"
      @change="handleChange"
    >
      <a-select-option v-for="v in data" :key="v" :value="v">
        {{ v }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
export default {
  name: 'InputArea',
  props: {
    value: {
      type: String,
      default: '',
    },
    field: {
      type: String,
      required: true,
    },
    prefecture: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default() {
        return 'large';
      },
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  emits: ['change', 'update:value'],
  data() {
    return {
      data: [],
      text: undefined,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        this.text = nv;
      },
    },
    prefecture: {
      immediate: true,
      handler(nv) {
        if (nv) {
          this.fetchData();
        }
      },
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const params = {};

      if (this.prefecture) {
        params.prefecture = this.prefecture;
      }

      const api = {
        small: 'simulation-areas/small',
        detail: 'simulation-areas/detail',
      };

      return this.$store
        .dispatch(api[this.field], params)
        .then(({ data }) => {
          this.data = data;

          return true;
        });
    },
    handleChange(value) {
      this.text = value;
      this.$emit('change', this.text);
      this.$emit('update:value', this.text);
    },
  },
};
</script>
