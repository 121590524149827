<template>
  <a-form
    ref="form"
    :model="form"
    :rules="rules"
    class="columns is-mini-gap is-multiline"
  >
    <a-form-item
      class="column is-12"
      :label="$t('Request ID')"
      name="requestId"
    >
      <a-input
        v-model:value="form.requestId"
        type="text"
        :size="size"
        :disabled="isNewVersion"
      />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Version ID')"
      name="version"
    >
      <a-input
        v-model:value="form.version"
        type="number"
        :size="size"
      />
    </a-form-item>
    <div
      v-if="error"
      class="column is-12"
    >
      <a-alert
        :message="error"
        type="error"
      />
    </div>

    <div class="column has-text-right is-12 m-t-4x">
      <a-button
        :disabled="isLoading"
        :size="size"
        class="button-secondary-outline m-r-2x"
        style="min-width: 110px;"
        @click="handleCancel"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="isLoading"
        :size="size"
        style="min-width: 110px;"
        type="primary"
        @click="handleConfirm"
      >
        {{ $t('Confirm') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n src="@/locales/components/simulations.json"></i18n>

<i18n>
{
  "en": {
    "Confirm": "Confirm"
  },
  "ja": {
    "Confirm": "Confirm"
  }
}
</i18n>

<script>
import { getResponseError } from '@/utils/util';

export default {
  name: 'RequestVersionForm',
  props: {
    isNewVersion: {
      type: Boolean,
    },
    requestId: {
      type: String,
      default: null,
    },
    version: {
      type: Number,
      default: 1,
    },
  },
  emits: ['cancel', 'confirm'],
  data() {
    return {
      isLoading: false,
      size: 'large',
      error: '',
      form: {
        requestId: undefined,
        version: undefined,
      },
      rules: {
        requestId: [
          {
            required: true,
            type: 'string',
            message: this.$t('Request ID is required'),
            trigger: ['change', 'blur'],
          },
        ],
        version: [
          {
            required: true,
            type: 'number',
            message: this.$t('Version ID is required'),
            transform: (value) => (Number(value) > 0 ? Number(value) : null),
            trigger: ['change', 'blur'],
          },
        ],
      },
    };
  },
  watch: {
    requestId: {
      immediate: true,
      handler(nv) {
        this.form.requestId = nv;
      },
    },
    version: {
      immediate: true,
      handler(nv) {
        this.form.version = nv;
      },
    },
  },
  methods: {
    async handleConfirm() {
      try {
        this.isLoading = true;
        await this.$refs.form.validate();

        const { data: existing } = await this.getExistingVersion();

        if (existing) {
          this.error = this.$t('simulation-exists');

          return;
        }

        this.$emit('confirm', this.form);
      } catch (e) {
        this.error = getResponseError(e);
      } finally {
        this.isLoading = false;
      }
    },
    async getExistingVersion() {
      return this.$store.dispatch('simulations/getVersion', {
        requestId: encodeURIComponent(this.form.requestId),
        version: this.form.version,
      });
    },
    resetFields() {
      this.$refs.form.resetFields();
    },
    handleCancel() {
      this.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
